import { graphql, navigate, useStaticQuery } from 'gatsby'
import React from 'react'
import Modal from '../../components/Modal/Modal'
import { useContext } from 'react'
import i18next from 'i18next'
import styles from './styles.module.scss'
import Link from '../../components/Link/Link'
import { StoreContext } from '../../store/context'
import { totalAmountFormatted, round } from '../../store/Store.helpers'

interface BudgetModal {
  title: string
  subtitle: string
  read_more: string
  cart_link: string
  bg_image: {
    source_url: string
  }
  titel_over_budget?: string
  subtitel_over_budget?: string
  read_more_over_budget?: string
  cart_link_over_budget?: string
  subtitel_info_over_budget?: string
}

interface StaticQueryProps {
  allWordpressPage: {
    nodes: Array<{ acf: { budget_modal: BudgetModal } }>
  }
}

interface Props {
  open: boolean
  toggle: () => void
}

const query = graphql`
  query budgetModalQuery {
    allWordpressPage(filter: { slug: { eq: "screens" } }) {
      nodes {
        acf {
          budget_modal {
            title
            subtitle
            cart_link
            read_more
            bg_image {
              source_url
            }
            titel_over_budget
            subtitel_over_budget
            subtitel_info_over_budget
            cart_link_over_budget
            read_more_over_budget
          }
        }
      }
    }
  }
`

const BudgetModal: React.FC<Props> = ({ open, toggle }) => {
  const data: StaticQueryProps = useStaticQuery(query)
  const {
    state: {
      shoppingCart: { totalAmount }
    }
  } = useContext(StoreContext)
  const fields: BudgetModal = data.allWordpressPage.nodes[0].acf.budget_modal

  const {
    title,
    subtitle,
    bg_image: { source_url },
    cart_link,
    read_more,
    titel_over_budget,
    subtitel_over_budget,
    subtitel_info_over_budget,
    cart_link_over_budget,
    read_more_over_budget
  } = fields

  const goTo = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    return navigate(e.currentTarget.pathname)
  }

  return (
    <Modal
      className={styles.budgetModal}
      backgroundImage={`linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url(${source_url}`}
      open={open}
      onClose={toggle}
    >
      {totalAmount > 19 ? (
        <>
          <p className={styles.title}>{titel_over_budget}</p>
          <p className={styles.subtitle}>{subtitel_info_over_budget}</p>
          <p className={styles.amount}>
            {round(totalAmount).toString().replace('.', ',')}&nbsp;
            <span>{i18next.t('checkout.kg')}</span>
          </p>
          <p className={styles.subtitle}>{subtitel_over_budget}</p>
          <Link href="/varukorg" onClick={goTo}>
            {cart_link_over_budget}
          </Link>
          <Link href="/veckobudgeten" onClick={goTo}>
            {read_more_over_budget}
          </Link>
        </>
      ) : (
        <>
          <p className={styles.title}>{title}</p>
          <p className={styles.amount}>
            {totalAmountFormatted(totalAmount)}&nbsp;<span>{i18next.t('checkout.kg')}</span>
          </p>
          <p className={styles.subtitle}>{subtitle}</p>
          <Link href="/varukorg" onClick={goTo}>
            {cart_link}
          </Link>
          <Link href="/veckobudgeten" onClick={goTo}>
            {read_more}
          </Link>
        </>
      )}
    </Modal>
  )
}

export default BudgetModal
