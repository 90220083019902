import React, { useEffect, useState } from 'react'
import { Impact, Product } from '../../models/Products'
import QuantityPicker from '../QuantityPicker/QuantityPicker'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import Modal from '../../components/Modal/Modal'
import climateBadge from '../../assets/svg/climate_icon.svg'
import { useContext } from 'react'
import { Scale } from '../Scale/Scale'
import { Badge } from '../Badge/Badge'
import { StoreContext } from '../../store/context'

type ProductItems = Pick<Product, 'brand' | 'country' | 'title' | 'image'>
export interface ProductCardProps extends ProductItems {
  category: Impact
  comparativePrice: string
  price: string
  onQuantityChange: (value: number) => void
  id: string
  type: string
}

const ProductCard: React.FC<ProductCardProps> = ({
  id,
  title,
  image,
  brand,
  country,
  price,
  comparativePrice,
  category,
  type,
  onQuantityChange
}) => {
  const {
    state: { shoppingCart }
  } = useContext(StoreContext)
  const [quantity, setQuantity] = useState(0)

  const getQuantity = (id: string): number => {
    if (!shoppingCart.cart || shoppingCart.cart.length === 0) return 0
    const item = shoppingCart.cart.filter((item) => item.id === id)
    if (!item) return 0
    return item[0]?.quantity
  }

  useEffect(() => {
    setQuantity(getQuantity(id) || 0)
  }, [])

  const [isOpen, setIsOpen] = React.useState(false)
  const { i18n } = useTranslation()
  //check for enter to open
  const handleKeyPress = (e: React.KeyboardEvent<HTMLImageElement>): void => {
    const keycode = e.key || e.keyCode
    keycode === 'Enter' || keycode === 13 ? setIsOpen(true) : ''
  }

  const toggleModal = () => {
    // GTM virtual page views
    if (typeof window !== undefined) {
      window.dataLayer?.push({
        event: 'virtual_pageview',
        pageData: {
          location: `https://www.klimatbutiken.com/klimatbutiken/butiken/${title}`
        }
      })
    }
    return setIsOpen(!isOpen)
  }

  const handleChange = (value: number) => {
    setQuantity(value)
    onQuantityChange(value)
  }

  const modalContent = () => {
    return (
      <>
        {category == '1' ? <img loading="lazy" src={climateBadge} className={styles.climateBadge} alt="Lågt klimatavtryck" /> : ''}
        <Badge size="bigger" className={styles.modalBadge} impact={category} />
        {image && <img loading="lazy" className={styles.img} src={image.media_details.sizes.medium.source_url} alt={title} />}

        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>
          {brand && <span>{brand} </span>} {brand && country && <span>&nbsp;/&nbsp;</span>} {country && <span>{country}</span>}
        </p>

        {comparativePrice && type === 'portion' ? (
          <p className={styles.description}>{i18n.t('products.jmf_portion', { comparativePrice: comparativePrice.replace('.', ',') })}</p>
        ) : (
          <p className={styles.description}>{i18n.t('products.jmf', { comparativePrice: comparativePrice.replace('.', ',') })}</p>
        )}

        <div className={styles.climateScaleDescription}>
          <p className={styles.description}>{i18n.t('products.scale')}</p>
          <Scale climate_impact={category} pricePosition={comparativePrice} type={type} />
          <a href="/felix-klimatskala-klimatmarkning" className={styles.description}>
            {i18n.t('products.read_more')}
          </a>
        </div>

        {price && (
          <p className={styles.price}>
            <span>{i18n.t('products.units', { price: price.replace('.', ',') })}</span>
            {i18n.t('products.st')}
          </p>
        )}

        <QuantityPicker
          greyBg
          max={7}
          value={quantity}
          onChange={handleChange}
          name={title}
          label={i18n.t('products.add_to_cart', { title })}
        />
      </>
    )
  }

  return (
    <>
      <li className={styles.productCard}>
        <Badge size="smaller" className={styles.badge} impact={category} />
        <div className={styles.productDetails} onClick={toggleModal} role="button" tabIndex={0} onKeyDown={handleKeyPress}>
          {image && <img loading="lazy" className={styles.img} src={image.media_details.sizes.medium.source_url} alt={title} />}
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.description}>
            {brand && <span>{brand}</span>}
            {brand && country && <span>&nbsp;/&nbsp;</span>}
            {country && <span>{country}</span>}
          </p>
        </div>
        {comparativePrice && type === 'portion' ? (
          <p className={styles.description}>{i18n.t('products.jmf_portion', { comparativePrice: comparativePrice.replace('.', ',') })}</p>
        ) : (
          <p className={styles.description}>{i18n.t('products.jmf', { comparativePrice: comparativePrice.replace('.', ',') })}</p>
        )}
        {price && (
          <p className={styles.price}>
            <span>{i18n.t('products.units', { price: price.replace('.', ',') || 0 })}</span>
            {i18n.t('products.st')}
          </p>
        )}
        <QuantityPicker
          greyBg
          max={7}
          value={quantity}
          onChange={handleChange}
          name={title}
          label={i18n.t('products.add_to_cart', { title })}
        />
      </li>

      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        {modalContent()}
      </Modal>
    </>
  )
}

export default React.memo(ProductCard)
