import React from 'react'
import { useState } from 'react'
import { Categories, Filter } from '../../../models/Categories'
import { Icon, ArrowDown } from '../../Icon'
import { replaceEntities } from '../Subnav.helpers'
import styles from './styles.module.scss'

export interface MenuItems {
  category: Categories
  childCategories?: Array<Categories>
  updateFilter: (value: Filter) => void
  close: () => void
}
const CategoryItem: React.FC<MenuItems> = ({ childCategories, updateFilter, category: { slug, name }, close }) => {
  const [showChildren, setShowChildren] = useState(false)
  const hasChildren = childCategories && childCategories?.length > 0

  const handleMenuItemChange = () => (hasChildren ? setShowChildren(!showChildren) : setFilter({ slug, name: replaceEntities(name) }))

  const setFilter = (slug: Filter) => {
    updateFilter(slug)
    return close()
  }

  return (
    <li className={styles.listItem} key={name}>
      <button className={styles.category} onClick={handleMenuItemChange}>
        {replaceEntities(name)} {hasChildren && <Icon icon={ArrowDown} size="small" rotate={showChildren} />}
      </button>

      {showChildren && (
        <ol>
          {childCategories?.map(({ slug, name }) => (
            <li key={name}>
              <button onClick={() => setFilter({ slug, name: replaceEntities(name) })} className={styles.childCategory}>
                {replaceEntities(name)}
              </button>
            </li>
          ))}
        </ol>
      )}
    </li>
  )
}

export default React.memo(CategoryItem)
