import classNames from 'classnames'
import React from 'react'
import styles from './styles.module.scss'

interface BadgeProps {
  impact: '1' | '2' | '3' | '4'
  className?: string
  size: 'smaller' | 'bigger'
}
export const Badge: React.FC<BadgeProps> = ({ impact, className = '', size }) => {
  const badgeStyle = (impact: string) => {
    switch (impact) {
      case '1':
        return 'lowest'
      case '2':
        return 'low'
      case '3':
        return 'medium'
      case '4':
        return 'high'
    }
  }
  return <span className={classNames(styles.badge, className, styles[`${badgeStyle(impact)}`], styles[size])}></span>
}
