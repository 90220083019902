import * as React from 'react'
import styles from './styles.module.scss'
import plus from '../../assets/svg/plus.svg'
import minus from '../../assets/svg/minus.svg'
import classNames from 'classnames'

export interface Props {
  value: number
  name: string
  label: string
  max: number
  ariaDescribedby?: string
  onChange: (value: number) => void
  className?: string
  greyBg?: boolean
}

export const QuantityPicker: React.FC<Props> = ({ value, ariaDescribedby, onChange, name, label, max, className, greyBg = false }) => {
  const decreaseDisabled = value === 0
  const maxDisabled = value >= max
  const id = name.replace(' ', '-')

  // restrict input field value to be between 0 and props.max
  const handleInputValue = (value: number, max: number): number => {
    if (value >= max) {
      return max
    } else {
      return value
    }
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const parsedValue = parseInt(e.target.value, 10) || 0
    const restrictedValue = handleInputValue(parsedValue, max)
    return onChange(restrictedValue)
  }

  return (
    <div className={classNames(styles.quantityPicker, className, { [styles.greyBg]: greyBg })}>
      <label className={styles.label} htmlFor={id} id={`QuantityPicker-${id}-label`}>
        {label}
      </label>
      <button
        className={styles.button}
        id={`${id}-decrease`}
        disabled={decreaseDisabled}
        aria-disabled={decreaseDisabled}
        onClick={() => onChange(value - 1)}
      >
        <img src={minus} alt="decrease amount" />
      </button>
      <input
        id={id}
        name={name}
        onChange={handleInputChange}
        value={value || 0}
        className={classNames(styles.input, { [styles.maxWidth]: greyBg })}
        type="number"
        aria-labelledby={`QuantityPicker-${id}-label`}
        aria-describedby={ariaDescribedby}
      />
      <button
        className={styles.button}
        id={`${id}-increase`}
        disabled={maxDisabled}
        aria-disabled={maxDisabled}
        onClick={() => onChange(value + 1)}
      >
        <img src={plus} alt="increase amount" />
      </button>
    </div>
  )
}

export default React.memo(QuantityPicker)
