import classNames from 'classnames'
import React, { HTMLAttributes } from 'react'
import { Icon } from '../Icon'
import ArrowLeft from '../Icon/Icons/ArrowLeft'
import styles from './styles.module.scss'

export interface LinkProps extends HTMLAttributes<HTMLAnchorElement> {
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
  href: string
  icon?: boolean
  button?: 'white' | 'green' | 'none'
}

const Link: React.FC<LinkProps> = ({ children, href, className, onClick, button = 'none', icon = false, ...attributes }) => {
  const classes = classNames(className, button !== 'none' ? styles.button : styles.link, { [styles[button]]: button !== 'none' })
  return (
    <a href={href} className={classes} onClick={onClick} {...attributes}>
      {icon && <Icon className={styles.icon} size="tiny" icon={ArrowLeft} />} <span className={styles.child}>{children}</span>
    </a>
  )
}

export default React.memo(Link)
