import React, { useContext, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Categories, Filter } from '../../models/Categories'
import { dataMangle } from './Subnav.helpers'
import CategoryItem from './components/CategoryItem'
import { Icon, ArrowDown } from '../Icon'
import { useTranslation } from 'react-i18next'
import BudgetModal from '../../screens/BudgetModal/BudgetModal'
import { totalAmountFormatted } from '../../store/Store.helpers'
import { StoreContext } from '../../store/context'
interface SubnavProps {
  categories: Array<Categories>
  setFilter: (filter: Filter) => void
  filter: Filter
}

const Subnav: React.FC<SubnavProps> = ({ categories, setFilter, filter }) => {
  const { i18n } = useTranslation()
  const [showCategories, setShowCategories] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const {
    state: { shoppingCart }
  } = useContext(StoreContext)
  const { showModal, totalAmount } = shoppingCart

  useEffect(() => {
    if (showModal) {
      toggleModal()
    }
  }, [showModal])

  const toggleNav = () => {
    return setShowCategories(!showCategories)
  }

  const toggleModal = () => {
    setOpenModal(!openModal)
  }

  const categoriesMangled = dataMangle(categories)

  const editedCategories = (): Array<Categories> => {
    if (filter.slug === '' || filter.slug === 'alla-produkter') {
      return categoriesMangled.filter((item) => item.slug !== 'alla-produkter')
    } else {
      return categoriesMangled
    }
  }
  const handleChange = (filter: Filter) => {
    // GTM virtual page views
    if (typeof window !== undefined) {
      const path = `https://www.klimatbutiken.com/klimatbutiken/butiken`
      window.dataLayer?.push({
        event: 'virtual_pageview',
        pageData: {
          location: filter.slug === 'alla-produkter' ? path : `${path}/${filter.slug}`
        }
      })
    }
    return setFilter(filter.slug === 'alla-produkter' ? { name: '', slug: '' } : filter)
  }

  const renderCategories = editedCategories().map((category) => {
    return (
      <CategoryItem
        key={category.name}
        category={category}
        close={toggleNav}
        updateFilter={handleChange}
        childCategories={category?.children}
      />
    )
  })

  return (
    <nav className={styles.subnav} aria-labelledby="sub-menu">
      <button onClick={toggleNav} className={styles.categoryToggle}>
        {filter.name !== '' ? filter.name : `Produkter`} <Icon icon={ArrowDown} size="small" rotate={showCategories} />
      </button>
      <button onClick={toggleModal} className={styles.cartAmount}>
        <span className={styles.saldo}>{i18n.t('subnav.saldo')}&nbsp;</span>
        <span>{totalAmountFormatted(totalAmount)}</span>
        {i18n.t('products.unit')}
      </button>
      {showCategories && <ol className={styles.categoriesList}>{renderCategories}</ol>}
      <BudgetModal open={openModal} toggle={toggleModal} />
    </nav>
  )
}

export default React.memo(Subnav)
