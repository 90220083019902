import * as React from 'react'
import ProductCard from '../../components/ProductCard/ProductCard'
import { Select } from '../../components/Select/Select'
import { ProductAcf, Impact, Product } from '../../models/Products'
import styles from './styles.module.scss'
import { useContext } from 'react'
import StatsCard from '../../components/StatsCard/StatsCard'
import ClimateScaleCard from '../../components/ScaleCard/ScaleCard'
import { pricePerType } from '../../store/Store.helpers'
import { Filter } from '../../models/Categories'
import { StoreContext } from '../../store/context'
import { Types } from '../../store/reducers/ShoppingCartReducer'
import { CardWithCategories } from '../../models/Cards'
import PosCard from '../../components/PosCard/PosCard'
import { groupBy, flattenDeep } from 'lodash'
export interface ProductsViewProps {
  products: Array<ProductAcf>
  cards: Array<CardWithCategories>
  categoryFilter: Filter
}

const ProductsView: React.FC<ProductsViewProps> = ({ products, cards, categoryFilter }) => {
  const [sortOption, setsortOption] = React.useState<Impact | string>('')

  const store = useContext(StoreContext)

  const handleQuantityChange = (product: Product, quantity: number, id: string) => {
    const payload = { product: { ...product, quantity, id } }
    return store.dispatch({ type: Types.ADD_PRODUCT, payload })
  }

  const addCardToProductsArray = (products: Array<ProductAcf>): Array<ProductAcf> => {
    let cardIndex = 0
    const filteredCards = cards.filter(
      (item) => item.node.categories && item.node.categories?.some((item) => item.slug === categoryFilter.slug)
    )
    return products.map((product, index) => {
      const lessThan = cardIndex < cards.length
      const indexCheck = (index + 1) % 4

      if (lessThan && !indexCheck) {
        const item = { ...product, card: categoryFilter.slug !== '' ? filteredCards[cardIndex] : cards[cardIndex] }
        cardIndex++
        return item
      } else {
        return product
      }
    })
  }

  const sortedProducts = (): Array<ProductAcf> => {
    if (sortOption !== '') {
      const list = products.map((item, index) => {
        return { index: index, value: parseFloat(item.node.acf.price_per_kilo), impact: parseFloat(item.node.acf.climate_impact) }
      })
      const sortedGroups = Object.values(groupBy(list, 'impact')).map((item) => {
        return item.sort((a, b) => a.value - b.value)
      })

      const merged = flattenDeep(sortedGroups)

      const resultList = merged.map((product) => {
        return products[product.index]
      })
      return sortOption === '4' ? resultList : resultList.reverse()
    }

    const featuredList = products.map((item, index) => {
      if (item.node.acf?.featured !== null && item.node.acf?.featured.length > 0) {
        return { index: index, featured: 1 }
      }
      return { index: index, featured: 0 }
    })

    featuredList.sort((a, b) => {
      if (a.featured > b.featured) {
        return -1
      }
      return 0
    })

    const resultList = featuredList.map((product) => {
      return products[product.index]
    })

    //finished list w featured items firt
    return resultList
  }

  const categoryFilteredProducts = () => {
    if (categoryFilter.slug !== '') {
      const prods = sortedProducts().filter((item) => {
        const categoryMatch = item.node?.categories?.some((item) => item.slug === categoryFilter.slug)
        return categoryMatch && item
      })
      return addCardToProductsArray(prods)
    } else {
      return addCardToProductsArray(sortedProducts())
    }
  }

  const options = [
    { value: '4', label: 'Lågt–högt kg CO₂e' },
    { value: '1', label: 'Högt–lågt kg CO₂e' }
  ]

  const renderCard = (card: CardWithCategories) => {
    const { scale_card, statistics_card, pos } = card.node.acf

    switch (card.node.acf.type) {
      case 'climate_scale':
        return (
          <ClimateScaleCard
            title={scale_card.title}
            animation={scale_card.animation}
            footer={scale_card.footer}
            bg_image={scale_card.bg_image}
          />
        )
      case 'stats_card':
        return (
          <StatsCard
            title={statistics_card.title}
            subtitle={statistics_card.subtitle}
            animation={statistics_card.animation}
            image={statistics_card.image}
            footer={statistics_card.footer}
          />
        )

      case 'pos_card':
        return (
          <PosCard
            title={pos.title}
            show_climate_impact={pos.show_climate_impact}
            logo={pos.logo}
            pos_bg_image={pos.pos_bg_image}
            link_to_examples={pos.link_to_examples}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className={styles.productsView}>
      <div className={styles.filterWrapper}>
        <Select className={styles.filter} options={options} label={''} onInputChange={setsortOption} />
      </div>
      <ol className={styles.productsList}>
        {categoryFilteredProducts().length === 0 ? (
          <li className={styles.emptyState}>
            Inga produkter hittades för kategori: <span className={styles.category}>{categoryFilter.name}</span>
          </li>
        ) : (
          categoryFilteredProducts().map((product, i) => {
            const {
              acf: { title, image, price_per_kilo, brand, country, climate_impact, type },
              id
            } = product.node

            return (
              <React.Fragment key={`${id}-${i}`}>
                <ProductCard
                  id={id}
                  key={`${title}-${i}`}
                  image={image}
                  price={pricePerType(product.node.acf) || '0'}
                  type={type}
                  comparativePrice={price_per_kilo}
                  title={title}
                  brand={brand}
                  country={country}
                  category={climate_impact}
                  onQuantityChange={(quantity) => handleQuantityChange(product.node.acf, quantity, product.node.id)}
                />
                {product.card && product.card && <li className={styles.cardWrapper}>{renderCard(product.card)}</li>}
              </React.Fragment>
            )
          })
        )}
      </ol>
    </div>
  )
}

export default React.memo(ProductsView)
