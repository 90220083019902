import * as React from 'react'
import styles from './styles.module.scss'
import ClimateScale from './ClimateScale'

export interface ScaleProps {
  climate_impact?: string
  pricePosition?: string
  type: string
}

export const Scale: React.FC<ScaleProps> = ({ pricePosition, type }) => {
  const displayUnit = (type: string) => {
    switch (type) {
      case 'portion':
        return 'per portion'
      case 'weight':
        return 'per kilo'
      case 'product':
        return 'per kilo'
    }
  }

  return (
    <>
      <div className={styles.wrapper}>
        <ClimateScale color="#000" position={`${pricePosition}`} unit={`${displayUnit(type)}`} />
      </div>
    </>
  )
}

export default Scale
