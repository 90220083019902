import * as React from 'react'

function SvgClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.015 10.015" {...props}>
      <g data-name="Group 127">
        <g data-name="Group 123">
          <path data-name="Path 32" d="M1.416 1.416l7.186 7.186" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth={2} />
        </g>
        <g data-name="Group 124">
          <path data-name="Path 32" d="M1.416 8.6l7.186-7.186" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth={2} />
        </g>
      </g>
    </svg>
  )
}

export default SvgClose
