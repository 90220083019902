import React, { useRef } from 'react'
import styles from './styles.module.scss'
import Lottie from '../Lottie/Lottie'
import { ScaleCard } from '../../models/ScaleCard'
import useIntersection from '../../hooks/useIntersection'

const ClimateScaleCard: React.FC<ScaleCard> = ({ title, footer, bg_image }) => {
  if (!title || !bg_image) return null
  const ref = useRef<HTMLElement | null>(null)
  const inViewport = useIntersection(ref, {})
  const bg = inViewport?.isIntersecting ? `, url(${bg_image?.media_details.sizes.medium?.source_url})` : ''

  return (
    <section
      className={styles.scaleCard}
      ref={ref}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25))${bg}`
      }}
    >
      <h3 className={styles.infoTextLarge}>{title}</h3>

      {inViewport?.isIntersecting ? (
        <Lottie animation={'climate_scale'} loopOpt={false} autoplay={false} />
      ) : (
        <span className={styles.animationWrapper}></span>
      )}

      {footer && inViewport?.isIntersecting && (
        <a className={styles.footer} href="/felix-klimatskala-klimatmarkning">
          {footer}
        </a>
      )}
    </section>
  )
}

export default React.memo(ClimateScaleCard)
