import * as React from 'react'
import ReactDom from 'react-dom'
import styles from './styles.module.scss'
import useLockBodyScroll from '../../hooks/useLockBodyScroll'
import classNames from 'classnames'
import { Icon } from '../Icon'
import { Close } from '../Icon/Icons'
export interface ModalProps {
  open: boolean
  onClose: (value: boolean) => void
  children?: React.ReactChild[] | React.ReactChild
  backgroundImage?: string
  className?: string
}

const portal = typeof document !== `undefined` ? document.getElementById('portal') : null

const Modal: React.FC<ModalProps> = ({ open, onClose, children, backgroundImage, className }) => {
  const focusRef = React.useRef<HTMLButtonElement>(null)

  React.useEffect(() => {
    focusRef.current && focusRef.current.focus()
  }, [open])

  const handleClick = () => {
    return onClose(!open)
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape' || e.keyCode === 27) {
      onClose(!open)
    }
  }

  useLockBodyScroll(open)

  if (portal && open)
    return ReactDom.createPortal(
      <div aria-modal="true" role="dialog" className={styles.wrapper}>
        <div className={classNames(styles.modal, className)} style={{ backgroundImage }}>
          <button onClick={handleClick} onKeyDown={handleKeyPress} ref={focusRef} aria-label="close" className={styles.close}>
            <Icon icon={Close} size="small" />
          </button>

          {children}
        </div>
      </div>,
      portal
    )
  return null
}

export default React.memo(Modal)
