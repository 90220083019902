import { navigate } from 'gatsby-link'
import React, { useRef } from 'react'
import useIntersection from '../../hooks/useIntersection'
import { Pos } from '../../models/Pos'
import styles from './styles.module.scss'
import { Badge } from '../Badge/Badge'
import { Impact } from '../../models/Products'

export const PosCard = ({ title, pos_bg_image, link_to_examples, show_climate_impact, logo }: Pos) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    navigate('/veckobudgeten')
  }

  const ref = useRef<HTMLElement | null>(null)
  const inViewport = useIntersection(ref, {})

  const impact: Array<{ title: string; impact: Impact }> = [
    {
      impact: '1',
      title: 'Väldigt lågt klimatavtryck'
    },
    {
      impact: '2',
      title: 'Lågt klimatavtryck'
    },
    {
      impact: '3',
      title: 'Medel klimatavtryck'
    },
    {
      impact: '4',
      title: 'Högt klimatavtryck'
    }
  ]

  const bg = inViewport?.isIntersecting ? `, url(${pos_bg_image.media_details.sizes.large.source_url})` : ''

  const fixedTitle = title.replace('®', '<span>®</span>')

  return (
    <section
      ref={ref}
      className={styles.posCard}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))${bg}`
      }}
    >
      {link_to_examples ? (
        <a className={styles.title} href="/veckobudgeten" onClick={handleClick}>
          {title}
        </a>
      ) : (
        <p className={styles.title} dangerouslySetInnerHTML={{ __html: fixedTitle }} />
      )}
      {logo && <img className={styles.logo} src={logo.media_details.sizes.medium.source_url} loading="lazy" alt="lågt klimatavtryck" />}

      {show_climate_impact && (
        <ul className={styles.impactList}>
          {impact.map(({ title, impact }) => (
            <li key={title}>
              <Badge impact={impact} size="bigger" />
              {title}
            </li>
          ))}
        </ul>
      )}
    </section>
  )
}

export default React.memo(PosCard)
