import React, { HTMLAttributes } from 'react'
import styles from './styles.module.scss'

export interface SelectProps extends HTMLAttributes<HTMLSelectElement> {
  label: string
  options: Array<{ value: string; label: string }>
  onInputChange: (value: string) => void
}
export const Select: React.FC<SelectProps> = ({ label, options, onInputChange, className }) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onInputChange(e.target.value)
  }
  return (
    <label className={className} htmlFor="category-filter">
      {label}
      <select className={styles.select} onChange={handleChange} onBlur={handleChange} name="category" id="category-filter">
        {options.map(({ value, label }) => (
          <option key={value} value={value} className={styles.options}>
            {label}
          </option>
        ))}
      </select>
    </label>
  )
}

export default React.memo(Select)
