import { CardWithCategories } from '../../models/Cards'

const splicer = (array: Array<CardWithCategories>, element: CardWithCategories, index: number) => {
  array.splice(index * 2, 0, element)
  return array
}

const weave = (array1: Array<CardWithCategories>, array2: Array<CardWithCategories>) => {
  return array1.reduce(splicer, array2.slice())
}

const sortCards = (cards: Array<CardWithCategories>, filterSlug: string) => {
  if (filterSlug !== '') {
    const scale = cards.filter((card) => card.node.acf.type === 'climate_scale')
    const stats = cards.filter((card) => card.node.acf.type === 'stats_card')
    const pos = cards.filter((card) => card.node.acf.type === 'pos_card')
    const outcome = weave(stats, pos)

    return [...scale, ...outcome]
  } else {
    return cards.sort((a, b) => a.node.acf.order - b.node.acf.order)
  }
}

export { sortCards }
