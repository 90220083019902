import { graphql } from 'gatsby'
import React, { useState } from 'react'
import IndexLayout from '../layouts'
import { get } from 'lodash'
import { ProductAcf, Products } from '../models/Products'
import { Categories } from '../models/Categories'

import ProductsView from '../views/ProductsView/ProductsView'
import Subnav from '../components/Subnav/Subnav'
import { CardWithCategories } from '../models/Cards'
import { sortCards } from '../views/ProductsView/ProductsView.helpers'
import { Helmet } from 'react-helmet'

interface IndexProps {
  data: {
    allWordpressWpCards: { edges: Array<CardWithCategories> }
    allWordpressWpProducts: Products
    allWordpressCategory: {
      nodes: Array<Categories>
    }
  }
}

const IndexPage: React.FC<IndexProps> = ({ data }) => {
  if (!data) return null

  const [filter, setFilter] = useState({ name: '', slug: '' })

  const products: Array<ProductAcf> = get(data, 'allWordpressWpProducts.edges')

  const meta = {
    title: 'Butiken | Klimatbutiken',
    description:
      'Använd vår digitala klimatbutik för att se vad olika produkter har för klimatavtryck och se om du klarar att plocka varor på ett klimatsmart sätt.'
  }

  return (
    <IndexLayout background="grey">
      <Helmet title={meta.title}>
        <meta property="og:title" content={meta.title} />
        <meta name="og:description" content={meta.description} />
        <meta name="description" content={meta.description} />
      </Helmet>
      <Subnav categories={data.allWordpressCategory.nodes} setFilter={(f) => setFilter(f)} filter={filter} />
      <ProductsView products={products} cards={sortCards(data.allWordpressWpCards.edges, filter.slug)} categoryFilter={filter} />
    </IndexLayout>
  )
}

export default React.memo(IndexPage)

export const query = graphql`
  query {
    allWordpressWpProducts {
      edges {
        node {
          id
          categories {
            name
            id
            slug
          }
          acf {
            brand
            country
            price_per_kilo
            title
            type
            price_per_product
            price_per_portion
            calculated_price
            climate_impact
            featured {
              value
            }
            image {
              alt_text
              media_details {
                sizes {
                  medium {
                    source_url
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressCategory {
      nodes {
        name
        slug
        parent_element {
          name
        }
      }
    }
    allWordpressWpCards {
      edges {
        node {
          id
          categories {
            name
            slug
          }
          acf {
            pos {
              link_to_examples
              show_climate_impact
              title
              logo {
                media_details {
                  sizes {
                    medium {
                      source_url
                    }
                  }
                }
              }
              pos_bg_image {
                media_details {
                  sizes {
                    large {
                      source_url
                    }
                  }
                }
              }
            }
            scale_card {
              footer
              title
              bg_image {
                media_details {
                  sizes {
                    medium {
                      source_url
                    }
                  }
                }
              }
            }
            statistics_card {
              animation
              footer
              subtitle
              title
              image {
                media_details {
                  sizes {
                    large {
                      source_url
                    }
                  }
                }
              }
            }
            type
            order
          }
        }
      }
    }
  }
`
