import React, { useRef } from 'react'
import styles from './styles.module.scss'
import { StatisticsCard } from '../../models/StatisticsCard'
import Lottie from '../Lottie/Lottie'
import useIntersection from '../../hooks/useIntersection'

const StatsCard: React.FC<StatisticsCard> = ({ image, title, footer, subtitle, animation }) => {
  if (!title || !animation) return null

  const ref = useRef<HTMLElement | null>(null)
  const inViewport = useIntersection(ref, {})

  const animationWidth = () => {
    switch (animation) {
      case 'group_wave':
        return '260px'
      case 'man_with_heart':
        return '80px'
      case 'man_with_questionmarks':
        return '100px'
      case 'man_with_earth':
        return '50px'
    }
  }
  const bg = inViewport?.isIntersecting ? `, url(${image.media_details.sizes.large.source_url})` : ''

  return (
    <section
      className={styles.statsCard}
      ref={ref}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25))${bg}`
      }}
    >
      <div className={animation !== 'group_wave' ? styles.animationWrapper : ''}>
        <Lottie animation={animation} width={animationWidth()} height="100%" loopOpt={true} />
      </div>

      <p className={styles.infoTextLarge}>{title}</p>
      {subtitle && <p className={styles.infoText}>{subtitle}</p>}
      {footer && <span className={styles.footer}>{footer}</span>}
    </section>
  )
}

export default React.memo(StatsCard)
