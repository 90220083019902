import { Categories } from '../../models/Categories'

/**
 * @summary replaces &amp; with a & in a string
 * @param text
 */
const replaceEntities = (text: string): string => {
  return text.replace('&amp;', '&')
}

/**
 * @summary takes the response from wordpress and constructs a array with correct json structure for the subnav
 * @param categories categories array
 */
const dataMangle = (categories: Array<Categories>) => {
  const parentCategories = categories.filter((category) => !category?.parent_element && category.name !== 'Uncategorized' && category)
  const childCategories = categories.filter((category) => category?.parent_element?.name && category)
  const mangle = parentCategories.map((category) => {
    const children = childCategories.filter((child) => child.parent_element.name === category.name)
    return { ...category, children }
  })
  return mangle
}

export { replaceEntities, dataMangle }
