import { useEffect } from 'react'
/**
 * @summary Hook that sets the styling of the body to be overflow hidden
 */
export const useLockBodyScroll = (shouldLock: boolean) => {
  useEffect(() => {
    if (typeof document !== 'undefined' && shouldLock) {
      // Get original body overflow
      const originalStyle = window.getComputedStyle(document.body).overflow
      // Prevent scrolling on mount
      document.body.style.overflow = 'hidden'
      const root = document.getElementById('___gatsby')
      root?.setAttribute('aria-hidden', 'true')
      // Re-enable scrolling when component unmounts
      return () => {
        document.body.style.overflow = originalStyle
        root?.setAttribute('aria-hidden', 'false')
      }
    }
  }, [shouldLock])
}

export default useLockBodyScroll
